import { EditorReadyFn, FlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { EditorSDK } from '@wix/editor-platform-sdk-types';
import {
  gridGeneratorLayoutLoaded,
  gridGeneratorButtonClick,
} from '@wix/bi-logger-wixlabs-users/v2';
import webBiLogger from '@wix/web-bi-logger';
import { Logger } from '@wix/yoshi-flow-editor/build/cjs/exports/bi';
import debounce from 'lodash/debounce';
import {
  AlignmentType,
  AppApi,
  AppSettings,
  AppSettingsUpdateListener,
  GridAppSettings,
  GridSettingsProps,
  GridType,
  HorizontalAppSettings,
  VerticalAppSettings,
  ViewportType,
  ViewPortUpdateListener,
} from './types';
import {
  defaultSettings,
  defaultEditorXSettings,
} from './common/settings';
import { getFrameUrl } from './utils/editor';
import gridAppComp from './components/gridApp/editor.controller';

let logger: Logger;
let metaSiteId: string;
let app_site_id: string;
let instanceId: string;
// -------------- APP LIFECYCLE

// token is used on most SDK calls even though it has no real effect
const TOKEN = 'token';
let currentViewPort = ViewportType.DESKTOP;
// -------------- APP INTERNAL LOGIC
let settingsInited = false;
const gridSettings: GridSettingsProps = {
  DESKTOP: [{ ...defaultSettings[ViewportType.DESKTOP] }],
  MOBILE: [{ ...defaultSettings[ViewportType.MOBILE] }],
};

// const gridGrid: Array<Array<Array<Array<number>>>> = [];
// let headerDimension: Layout;
// let footerDimension: Layout;
const listeners: Array<AppSettingsUpdateListener> = [];
const viewportTypeListeners: Array<ViewPortUpdateListener> = [];
let isClassicEditor: boolean = true;
// editorReady is executed once after the app is installed or the editor loads
// firstInstall flag indicates its the first run after app installation
type anyArgs = Array<any>;
const debugMessage = (...args: anyArgs) => {
  // console.log('grid component', ...args);
};

export const editorReady: EditorReadyFn = async (
  editorSDK: FlowEditorSDK,
  token: string,

  { firstInstall, initialAppData },
  flowAPI: FlowAPI,
) => {
  // debugMessage(firstInstall ? 'FIRST RUN' : 'RUN');

  if (flowAPI.environment.isEditorX) {
    isClassicEditor = false;
    gridSettings[ViewportType.DESKTOP] = [
      { ...defaultEditorXSettings[ViewportType.DESKTOP] },
    ];
    gridSettings[ViewportType.MOBILE] = [
      { ...defaultEditorXSettings[ViewportType.MOBILE] },
    ];
  }
  // register canvas overlay to the editor stage
  openCanvasOverlay(editorSDK);
  // open side panel -> will be replaced by register tool panel
  openSidePanel(editorSDK, flowAPI);

  editorSDK.addEventListener('switchedToMobileView', (event) => {
    currentViewPort = ViewportType.MOBILE;
    viewportTypeListeners.forEach((cb) => cb(currentViewPort));
  });
  editorSDK.addEventListener('switchedToDesktopView', (event) => {
    currentViewPort = ViewportType.DESKTOP;
    viewportTypeListeners.forEach((cb) => cb(currentViewPort));
  });

  const promises = [
    editorSDK?.info.getMetaSiteId(''),
    editorSDK?.info.getSiteId(''),
    editorSDK.info.getAppInstanceId(''),
  ];
  Promise.all(promises)
    .then((values) => {
      metaSiteId = values[0];
      app_site_id = values[1];
      instanceId = values[2];
    })
    .catch((e) => console.log('no promises', e));
};

// exports is called before editorReady, executed once on editor load
// here you can expose different types of APIs that are exposed to different audiences
export const exports = (editorSdk: FlowEditorSDK, flowAPI: FlowAPI) => ({
  // public - api that is available for other apps
  public: createPublicAPI(editorSdk, flowAPI),

  // private - api that is available for the app usage only
  // available in other frames (panels/overlays) opened by the app
  private: createPrivateAPI(editorSdk),
});

// -------------- APP SETUP HELPERS
const sendLayoutLoadedBI = (appSettings: AppSettings) => {
  let currentSettings;
  try {
    switch (appSettings.gridType) {
      case GridType.Vertical:
        currentSettings = appSettings as VerticalAppSettings;
        logger?.report(
          gridGeneratorLayoutLoaded({
            app_site_id,
            color_code: currentSettings.color,
            colum_number: currentSettings.cols,
            column_width: currentSettings.columnWidth,
            device_view: currentViewPort,
            gutter: currentSettings.gutter,
            instance_id: instanceId,
            layout_id: currentSettings.id,
            margin: currentSettings.margins,
            biToken: metaSiteId,
            page_id: app_site_id,
            snap_to_grid: false,
            type: 'columns',
            type_location: currentSettings.gridType,
          }),
        );
        break;
      case GridType.Horizontal:
        currentSettings = appSettings as HorizontalAppSettings;
        logger?.report(
          gridGeneratorLayoutLoaded({
            app_site_id,
            color_code: currentSettings.color,
            device_view: currentViewPort,
            gutter: currentSettings.gutter,
            instance_id: instanceId,
            layout_id: currentSettings.id,
            margin: currentSettings.margins,
            biToken: metaSiteId,
            page_id: app_site_id,
            row_height: currentSettings.rowHeight,
            row_number: currentSettings.rows,
            snap_to_grid: false,
            type: 'rows',
            type_location: currentSettings.gridType,
          }),
        );
        break;
      case GridType.Grid:
        currentSettings = appSettings as GridAppSettings;
        logger?.report(
          gridGeneratorLayoutLoaded({
            app_site_id,
            cell_size: currentSettings.cellSize,
            color_code: currentSettings.color,
            device_view: currentViewPort,
            instance_id: instanceId,
            layout_id: currentSettings.id,
            biToken: metaSiteId,
            page_id: app_site_id,
            snap_to_grid: false,
            type: 'grid',
          }),
        );
        break;
    }
  } catch (e) {
    console.log('bi log failed', e);
  }
};
const debounced = debounce(sendLayoutLoadedBI, 100, { maxWait: 1000 });

const createPrivateAPI = (editorSDK: FlowEditorSDK): AppApi => {
  logger = webBiLogger.factory().logger();

  return {
    getSettings: () => {
      debugMessage('getSettings', isClassicEditor, gridSettings);
      return gridSettings;
    },
    getIsClassicEditor: (): boolean => isClassicEditor,
    updateSettings: async (newSettings: AppSettings, index: number) => {
      let oldSettings = {};
      const oldSettingsIndex = gridSettings[currentViewPort].findIndex(
        (item) => item.id === newSettings.id,
      );
      const layoutIndex = oldSettingsIndex === -1 ? index : oldSettingsIndex;

      oldSettings = { ...gridSettings[currentViewPort][layoutIndex] };
      gridSettings[currentViewPort][layoutIndex] = {
        ...gridSettings[currentViewPort]?.[layoutIndex],
        ...newSettings,
      };
      if (
        layoutIndex === -1 ||
        (gridSettings[currentViewPort][layoutIndex] &&
          JSON.stringify(gridSettings[currentViewPort][layoutIndex]) !==
          JSON.stringify(oldSettings))
      ) {
        debugMessage('updateSettings', {
          oldSettings,
          newSettings,
          gridSettings,
          layoutIndex,
          oldSettingsIndex
        });

        listeners.forEach((cb) => cb(gridSettings[currentViewPort]));
        debounced(newSettings);
        // sendLayoutLoadedBI(newSettings);
      }
      else {
        debugMessage('updateSettings', 'update not changed data');
      }
    },
    initSettings: async (newSettings: GridSettingsProps) => {
      if (!settingsInited) {
        gridSettings[ViewportType.DESKTOP] = [];
        gridSettings[ViewportType.MOBILE] = [];
        newSettings[ViewportType.DESKTOP].forEach(
          (layerSettings: AppSettings) => {
            gridSettings[ViewportType.DESKTOP].push({ ...layerSettings });
          },
        );

        newSettings[ViewportType.MOBILE].forEach(
          (layerSettings: AppSettings) => {
            gridSettings[ViewportType.MOBILE].push({ ...layerSettings });
          },
        );
        listeners.forEach((cb) => cb(gridSettings[currentViewPort]));

        gridSettings[currentViewPort].forEach((appSettings) =>
          sendLayoutLoadedBI(appSettings),
        );
        settingsInited = true;
      }
    },
    registerSettingsListener: (cb: AppSettingsUpdateListener) => {
      listeners.push(cb);
      cb(gridSettings[currentViewPort]);
    },
    registerViewPortListener: (cb: ViewPortUpdateListener) => {
      viewportTypeListeners.push(cb);
      cb(currentViewPort);
    },

    deleteSettings: (index: number) => {
      gridSettings[currentViewPort].splice(index, 1);
      // debugMessage('deleteSettings', { gridSettings, index });

      listeners.forEach((cb) => cb(gridSettings[currentViewPort]));
      try {
        logger?.report(
          gridGeneratorButtonClick({
            app_site_id,
            button_name: 'delete',
            instance_id: instanceId,
            biToken: metaSiteId,
          }),
        );
      } catch (e) {
        console.log('bi log failed', e);
      }
    },
    setCanvasDimensions: () => { },
  };
};

const createPublicAPI = (editorSdk: FlowEditorSDK, flowAPI: FlowAPI) => ({
  openSidePanel: () => openSidePanel(editorSdk, flowAPI),
  openCanvasOverlay: () => openCanvasOverlay(editorSdk),
  closeCanvasOverlay: () => closeCanvasOverlay(editorSdk),
});

const openCanvasOverlay = async (editorSdk: FlowEditorSDK) => {
  editorSdk.editor.canvasOverlay.add(TOKEN, {
    url: getFrameUrl(gridAppComp.type, 'Overlay'),
  });
};

const closeCanvasOverlay = async (editorSdk: FlowEditorSDK) => {
  await editorSdk.editor.canvasOverlay.remove(TOKEN);
};

const openSidePanel = async (editorSdk: FlowEditorSDK, flowAPI: FlowAPI) => {
  const toolsMenuItemOptions = {
    title: flowAPI.translations.t('app.title'),
    initialData: { isClassicEditor: flowAPI.environment.isClassicEditor },
  };

  const toolsPanelOptions = {
    title: flowAPI.translations.t('app.settings.title'),
    width: 250,
    height: 545,
    url: getFrameUrl(gridAppComp.type, 'Side'),
    initialPosition: { x: 100, y: 100 },
    initialData: { isClassicEditor: flowAPI.environment.isClassicEditor },
  };
  await editorSdk.editor.registerToolsPanel(
    '',
    toolsMenuItemOptions,
    toolsPanelOptions,
  );
};
