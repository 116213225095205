
  var editorScriptEntry = require('/home/builduser/work/2d815f4a486046ad/packages/grid-generator-addon/src/editor.app.ts');

  
    if (editorScriptEntry.default) {
      module.exports = editorScriptEntry.default;
    } else {
      const { editorScriptBuilder } = require('@wix/bob-widget-services');
      const { editorReadyWrapper, createFlowAPIFabric, exportsWrapper } = require('@wix/yoshi-flow-editor/runtime/esm/editorScript.js');

      const biLogger = require('/home/builduser/work/2d815f4a486046ad/packages/grid-generator-addon/target/generated/bi/createBILogger.ts').createOwnerBILogger;

      

  const { initI18n: initI18n } = require('@wix/yoshi-flow-editor/runtime/esm/i18next/init');


      

    var createExperiments = null;
    var createWidgetExperiments = null;
    

      
const { createHttpClient } = require('@wix/yoshi-flow-editor/runtime/esm/controller/httpClientProp');
    


      const sentryConfig = null;
      const experimentsConfig = null;
      const translationsConfig = {"enabled":true,"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/2d815f4a486046ad/packages/grid-generator-addon/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hr","hu","id","it","ja","ko","lt","lv","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};
      const defaultTranslations = {"app.settings.alignment.center":"Center","app.settings.alignment.left":"Left","app.settings.alignment.right":"Right","app.settings.alignment.top":"Top","app.settings.alignment.bottom":"Bottom","app.settings.alignment.stretch":"Stretch","app.settings.alignment.title":"Alignment","app.settings.autoValue":"Auto","app.settings.color":"Fill color & opacity","app.settings.columns":"Columns","app.settings.columnWidth":"Column width","app.settings.totalWidth":"Total width","app.settings.enable":"Show","app.settings.disable":"Hide","app.settings.enableSnap":"Snap to guides","app.settings.enableStretch":"Enable Stretch","app.settings.gridType.grid":"Grid","app.settings.gridType.horizontal":"Rows","app.settings.gridType.title":"Type of guide","app.settings.gridType.vertical":"Columns","app.settings.gutter_vertical":"Gutter width","app.settings.gutter_horizontal":"Gutter height","app.settings.margins_horizontal":"Margin height","app.settings.margins_vertical":"Margin width","app.settings.offset":"Offset","app.settings.opacity":"Opacity","app.settings.title":"Design Guides","app.settings.rowHeight":"Row height","app.settings.rows":"Rows","app.settings.cellSize":"Cell size","app.settings.cellSize.tooltip":"Minimum cell size is 10px.","app.settings.backToDefault":"Back to default","app.settings.addLayer":"Add Guide","app.settings.removeLayer":"Delete guide","app.settings.rowslayer.title":"Rows ({rows, plural, other{#}})","app.settings.columnslayer.title":"Columns ({columns, plural, other{#}})","app.settings.grid.title":"Grid","app.title":"Design Guides"};

      const createFlowAPI = createFlowAPIFabric({
        sentryConfig,
        experimentsConfig,
        translationsConfig,
        defaultTranslations,
        biLogger,
        shouldUseEssentials: true,
        artifactId: 'wixlabs-grid-generator',
        appDefId: '7f903d78-65fc-4b4c-8e29-eda31fb4e1a5',
        optionalDeps: {
          initI18n,
          createExperiments,
          createHttpClient
        },
        localeDistPath: 'assets/locales',
      });

      const useAPIsFromExports = typeof editorScriptEntry.exports === "function";

      function wrapEditorReady(eReady) {
        try {
          return editorReadyWrapper({
            createFlowAPI,
            editorReady: eReady,
            shouldSkipAPIOverrides: useAPIsFromExports,
          });
        } catch (e) {
          console.error(e);
          throw e;
        }
      }

      let wrappedEditorReady = wrapEditorReady(editorScriptEntry.editorReady);
      if (false) {
        const hot = require('@wix/yoshi-flow-editor/build/cjs/wrappers/editorScriptHot.js').hot;
        wrappedEditorReady = hot(module, wrappedEditorReady);
      }

      let builder = editorScriptBuilder();
      if (editorScriptEntry.editorReady) {
        builder = builder.withEditorReady(wrappedEditorReady);
      }

      if (editorScriptEntry.exports) {
        builder = builder.withExports(useAPIsFromExports ? exportsWrapper(editorScriptEntry.exports) : editorScriptEntry.exports);
      }

      if (editorScriptEntry.onEvent) {
        builder = builder.withEventHandler(editorScriptEntry.onEvent);
      }
      const controllerWidgetManifests = [];
      
      const userController_0 = require('/home/builduser/work/2d815f4a486046ad/packages/grid-generator-addon/src/components/gridApp/editor.controller.ts');

      const model_0 = null;

      const manifest_0 = userController_0 ? userController_0.default || userController_0 : {};
      if (!manifest_0.type) {
        manifest_0.type = "undefined";
      }
      if (!manifest_0.getExports && model_0) {
        manifest_0.getExports = () => ({"undefined": model_0.getExports()});
      }
      if (userController_0 && userController_0.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "undefined", getWidgetManifest: userController_0.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_0);

      if (editorScriptEntry.getAppManifest || controllerWidgetManifests.length) {
        const customGetAppManifest = async (...params) => {
          const [{ appManifestBuilder }, editorSDK, contextParams] = params;
          const flowAPI = await createFlowAPI(editorSDK, contextParams);
          controllerWidgetManifests.forEach(({ widgetId, getWidgetManifest}) => {
            appManifestBuilder.configureWidget(widgetId, (builder) => getWidgetManifest(builder, editorSDK, flowAPI));
          });

          if (editorScriptEntry.getAppManifest) {
            return editorScriptEntry.getAppManifest(...params, flowAPI);
          }

          return appManifestBuilder.build();
        };
        builder = builder.withAppManifest(customGetAppManifest);
      }
      var result = builder.build();
      module.exports = result;
  }
  
