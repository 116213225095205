interface CommonAppSettings {
  id: string;
  color: string;
  opacity: number;
  gridType: GridType;
  enabled: boolean;
}

export interface GridAppSettings extends CommonAppSettings {
  cellSize: number;
}

export interface VerticalAppSettings extends CommonAppSettings {
  cols: number;
  gutter: number;
  margins: number;
  alignment: AlignmentType;
  columnWidth: number;
  offset: number;
  width: number;
}

export interface HorizontalAppSettings extends CommonAppSettings {
  gutter: number;
  margins: number;
  alignment: AlignmentType;
  rowHeight: number;
  rows: number;
  offset: number;
}
export type AppSettings =
  | GridAppSettings
  | VerticalAppSettings
  | HorizontalAppSettings;

export enum GridType {
  Vertical = 'VERTICAL',
  Horizontal = 'HORIZONTAL',
  Grid = 'GRID',
}

export enum AlignmentType {
  Stretch = 'STRETCH',
  Left = 'LEFT',
  Right = 'RIGHT',
  Center = 'CENTER',
  Top = 'TOP',
  Bottom = 'BOTTOM',
}

export interface CanvasDimensions {
  width: number;
  height: number;
}
export type MultiLayerSettings = Array<AppSettings>;

export enum ViewportType {
  DESKTOP = 'DESKTOP',
  MOBILE = 'MOBILE',
}

export type GridSettingsProps = {
  [ViewportType.DESKTOP]: Array<AppSettings>;
  [ViewportType.MOBILE]: Array<AppSettings>;
};
