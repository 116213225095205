import { v4 as uuidv4 } from 'uuid';
import {
  AlignmentType,
  GridAppSettings,
  GridType,
  HorizontalAppSettings,
  VerticalAppSettings,
  ViewportType,
} from '../types';

const DefaultColor = {
  color: '#ff0000',
  opacity: 10,
};
export type GridAppSettingsPerPort = {
  [ViewportType.DESKTOP]: GridAppSettings;
  [ViewportType.MOBILE]: GridAppSettings;
};

export const GridDefaultSettings: GridAppSettingsPerPort = {
  [ViewportType.DESKTOP]: {
    cellSize: 20,
    enabled: true,
    // enableSnap: false,
    // enableStretch: false,
    gridType: GridType.Grid,
    id: uuidv4(),
    ...DefaultColor,
    opacity: 25,
  },
  [ViewportType.MOBILE]: {
    cellSize: 20,
    enabled: true,
    // enableSnap: false,
    // enableStretch: false,
    gridType: GridType.Grid,
    id: uuidv4(),
    ...DefaultColor,
    opacity: 25,
  },
};
type VerticalSettingsList = {
  [AlignmentType.Center]: VerticalAppSettings;
  [AlignmentType.Left]: VerticalAppSettings;
  [AlignmentType.Right]: VerticalAppSettings;
  [AlignmentType.Stretch]: VerticalAppSettings;
};
type HorizontalSettingsList = {
  [AlignmentType.Stretch]: HorizontalAppSettings;
  [AlignmentType.Top]: HorizontalAppSettings;
  [AlignmentType.Bottom]: HorizontalAppSettings;
  [AlignmentType.Center]: HorizontalAppSettings;
};

export type VerticalAppSettingsPerPort = {
  [ViewportType.DESKTOP]: VerticalSettingsList;
  [ViewportType.MOBILE]: VerticalSettingsList;
};

export type HorizontalAppSettingsPerPort = {
  [ViewportType.DESKTOP]: HorizontalSettingsList;
  [ViewportType.MOBILE]: HorizontalSettingsList;
};

export const VerticalDefaultSettings: VerticalAppSettingsPerPort = {
  [ViewportType.DESKTOP]: {
    [AlignmentType.Center]: {
      gridType: GridType.Vertical,
      alignment: AlignmentType.Center,
      width: 980,
      cols: 8,
      margins: 20,
      gutter: 24,
      enabled: true,
      columnWidth: 20,
      offset: 0,
      id: uuidv4(),
      ...DefaultColor,
    },
    [AlignmentType.Stretch]: {
      gridType: GridType.Vertical,
      alignment: AlignmentType.Stretch,
      width: 980,
      cols: 8,
      margins: 0,
      gutter: 24,
      enabled: true,
      columnWidth: 20,
      offset: 0,
      id: uuidv4(),
      ...DefaultColor,
    },
    [AlignmentType.Left]: {
      gridType: GridType.Vertical,
      alignment: AlignmentType.Left,
      width: 980,
      cols: 8,
      margins: 0,
      gutter: 24,
      enabled: true,
      columnWidth: 20,
      offset: 0,
      id: uuidv4(),
      ...DefaultColor,
    },
    [AlignmentType.Right]: {
      gridType: GridType.Vertical,
      alignment: AlignmentType.Right,
      width: 980,
      cols: 8,
      margins: 0,
      gutter: 24,
      enabled: true,
      columnWidth: 20,
      offset: 0,
      id: uuidv4(),
      ...DefaultColor,
    },
  },
  [ViewportType.MOBILE]: {
    [AlignmentType.Center]: {
      gridType: GridType.Vertical,
      alignment: AlignmentType.Center,
      width: 320,
      cols: 4,
      margins: 20,
      gutter: 16,
      enabled: true,
      columnWidth: 20,
      offset: 0,
      id: uuidv4(),
      ...DefaultColor
    },
    [AlignmentType.Stretch]: {
      gridType: GridType.Vertical,
      alignment: AlignmentType.Stretch,
      width: 320,
      cols: 4,
      margins: 20,
      gutter: 16,
      enabled: true,
      columnWidth: 20,
      offset: 0,
      id: uuidv4(),
      ...DefaultColor,
    },
    [AlignmentType.Left]: {
      gridType: GridType.Vertical,
      alignment: AlignmentType.Left,
      width: 320,
      cols: 4,
      margins: 20,
      gutter: 16,
      enabled: true,
      columnWidth: 20,
      offset: 0,
      id: uuidv4(),
      ...DefaultColor,
    },
    [AlignmentType.Right]: {
      gridType: GridType.Vertical,
      alignment: AlignmentType.Right,
      width: 320,
      cols: 4,
      margins: 20,
      gutter: 16,
      enabled: true,
      columnWidth: 20,
      offset: 0,
      id: uuidv4(),
      ...DefaultColor,
    },
  },
};

export const HorizontalDefaultSettings: HorizontalAppSettingsPerPort = {
  [ViewportType.DESKTOP]: {
    [AlignmentType.Stretch]: {
      rows: 4,
      margins: 0,
      gutter: 20,
      enabled: true,
      rowHeight: 20,
      offset: 0,
      gridType: GridType.Horizontal,
      alignment: AlignmentType.Stretch,
      id: uuidv4(),
      ...DefaultColor,
    },
    [AlignmentType.Center]: {
      rows: 4,
      margins: 0,
      gutter: 20,
      enabled: true,
      rowHeight: 20,
      offset: 0,
      gridType: GridType.Horizontal,
      alignment: AlignmentType.Center,
      id: uuidv4(),
      ...DefaultColor,
    },
    [AlignmentType.Top]: {
      rows: 4,
      margins: 0,
      gutter: 20,
      enabled: true,
      rowHeight: 20,
      offset: 0,
      gridType: GridType.Horizontal,
      alignment: AlignmentType.Top,
      id: uuidv4(),
      ...DefaultColor,
    },
    [AlignmentType.Bottom]: {
      rows: 4,
      margins: 0,
      gutter: 20,
      enabled: true,
      rowHeight: 20,
      offset: 0,
      gridType: GridType.Horizontal,
      alignment: AlignmentType.Bottom,
      id: uuidv4(),
      ...DefaultColor,
    },
  },
  [ViewportType.MOBILE]: {
    [AlignmentType.Stretch]: {
      rows: 4,
      margins: 0,
      gutter: 20,
      enabled: true,
      rowHeight: 20,
      offset: 0,
      gridType: GridType.Horizontal,
      alignment: AlignmentType.Stretch,
      id: uuidv4(),
      ...DefaultColor,
    },
    [AlignmentType.Center]: {
      rows: 4,
      margins: 0,
      gutter: 20,
      enabled: true,
      rowHeight: 20,
      offset: 0,
      gridType: GridType.Horizontal,
      alignment: AlignmentType.Center,
      id: uuidv4(),
      ...DefaultColor,
    },
    [AlignmentType.Top]: {
      rows: 4,
      margins: 0,
      gutter: 20,
      enabled: true,
      rowHeight: 20,
      offset: 0,
      gridType: GridType.Horizontal,
      alignment: AlignmentType.Top,
      id: uuidv4(),
      ...DefaultColor,
    },
    [AlignmentType.Bottom]: {
      rows: 4,
      margins: 0,
      gutter: 20,
      enabled: true,
      rowHeight: 20,
      offset: 0,
      gridType: GridType.Horizontal,
      alignment: AlignmentType.Bottom,
      id: uuidv4(),
      ...DefaultColor,
    },
  },
};

export const defaultSettings = {
  [ViewportType.DESKTOP]:
    VerticalDefaultSettings[ViewportType.DESKTOP][AlignmentType.Center],
  [ViewportType.MOBILE]:
    VerticalDefaultSettings[ViewportType.MOBILE][AlignmentType.Center],
};

export const defaultEditorXSettings = {
  [ViewportType.DESKTOP]:
    VerticalDefaultSettings[ViewportType.DESKTOP][AlignmentType.Stretch],
  [ViewportType.MOBILE]:
    VerticalDefaultSettings[ViewportType.MOBILE][AlignmentType.Center],
};
